import React from 'react';

const Intro: React.FC = () => {
  return (
    <>
      <div id="home" className="intro">
        <div className="container">
          <div className="content">
            <div className="row align-items-center">
              <div className="col-md-12 col-sm-12 col-lg-7">
                <div className="content-text">
                  <h1>A Pan-African hub for #TheFutureofWork</h1>
                  <p>
                    Creating a vibrant, inclusive and innovative community that fosters the growth of African entrepreneurs and creatives, whilst celebrating the continent's rich cultural heritage
                  </p>
                </div>
              </div>
              <div className="col-md"></div>
            </div>

            <div className="row">
              <div className="col-md"></div>
              <div className="col-md-8">
                <img
                  src="https://cdn.pixabay.com/photo/2022/01/20/17/51/office-desk-6952917_1280.jpg"
                  alt="office desk"
                  style={{ width: '80%', height: 'auto' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="about">
        <div className="container">
          <h2>About us</h2>
          <p>
            The Opera is a boutique coworking lab that blends African culture, art, and innovation, to create a unique and inspiring work environment.
          </p>
        </div>
      </div>
    </>
  );
};

export default Intro;
