import './App.css';
import Footer from './sections/footer';
import Contact from './sections/contacts';
import Features from './sections/features';
import Gallery from './sections/gallery';
import Testimonial from './sections/testimonial';
import Space from './sections/space';
import Navbar from './sections/navbar';
import Intro from './sections/intro';

function App() {
  return (
    <div className="App">
      <Intro />
      <Navbar />
      <Space />
      <Testimonial />
      <Gallery />
      <Features />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
