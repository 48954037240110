import React from 'react';

const Space: React.FC = () => {
  return (
    <div id="space" className="space">
      <div className="container">
        <div className="row gx-5">
          {/* Left Section */}
          <div className="col-md">
            <h2>The Opera Space Offers</h2>
            <img src="img/space1.png" alt="Opera Space" />
            <h3>COME JOIN US</h3>
            <a href="mailto:hello@theoperagroup.org" className="button">
              Get Started
            </a>
          </div>

          {/* Boardroom and Team Space */}
          <div className="col-md col-sm-6">
            <div className="content">
              <img
                src="https://cdn.pixabay.com/photo/2016/11/07/19/25/meeting-room-1806702_1280.jpg"
                alt="Boardroom"
              />
              <h5>Boardroom</h5>
              <p>$20/Hour</p>
            </div>

            <div className="content">
              <img src="img/space4.png" alt="Team Space" />
              <h5>Team Space</h5>
              <p>$20/Day - $140/Week - $400/Month</p>
            </div>
          </div>

          {/* Individual Space and Event Space */}
          <div className="col-md col-sm-6">
            <div className="content">
              <img src="img/space3.png" alt="Individual Space" />
              <h5>Individual Space</h5>
              <p>$23/Day - $140/Week - $500/month</p>
            </div>

            <div className="content">
              <img src="img/space5.png" alt="Event Space" />
              <h5>Event Space</h5>
              <p>$150/Day - $1000/Week - $4000/Month</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Space;
