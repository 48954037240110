import React from 'react';

const Features: React.FC = () => {
  return (
    <div id="features" className="features">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Column: Text Content */}
          <div className="col-md-5">
            <div className="content-left">
              <h2>The Features You Will Get</h2>
              <p>Including flexible workspaces and meeting room.</p>
              <h5>Our DNA</h5>
              <ul>
                <li>Pan-Africanism</li>
                <li>Art and Innovation</li>
                <li>The Future of Work</li>
              </ul>
            </div>
          </div>

          {/* Right Column: Feature Details */}
          <div className="col-md">
            <div className="content-right">
              <div className="row gx-5">
                {/* Feature 1: Faster Internet */}
                <div className="col">
                  <div className="content content1">
                    <i className="la la-network-wired"></i>
                    <h5>Faster Internet</h5>
                    <p>Including flexible workspaces and a meeting room</p>
                  </div>
                </div>

                {/* Feature 2: Multipurpose Venue */}
                <div className="col">
                  <div className="content content2">
                    <i className="la la-microphone"></i>
                    <h5>Multipurpose Venue</h5>
                    <p>Including event hosting venue, piped music and media lab</p>
                  </div>
                </div>
              </div>

              <div className="row gx-5">
                {/* Feature 3: Parking Area */}
                <div className="col">
                  <div className="content content3">
                    <i className="la la-parking"></i>
                    <h5>Parking Area</h5>
                    <p>Secure and ample for you and your guests</p>
                  </div>
                </div>

                {/* Feature 4: Kitchenette */}
                <div className="col">
                  <div className="content content4">
                    <i className="la la-utensils"></i>
                    <h5>Kitchenette</h5>
                    <p>
                      Culinary and Wine Tasting experiences. A free glass of wine,
                      or a cup of coffee, tea or chocolate to go with your work mode
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
