import React from 'react';
const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md col-sm-6">
            <ul>
              <li>
                <a href="#" aria-label="Facebook">
                  <i className="la la-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" aria-label="Twitter">
                  <i className="la la-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" aria-label="Instagram">
                  <i className="la la-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" aria-label="YouTube">
                  <i className="la la-youtube"></i>
                </a>
              </li>
              <li>
                <a href="#" aria-label="LinkedIn">
                  <i className="la la-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md col-sm-6">
            <div className="cp">
              <span>Copyright © All Right Reserved</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
