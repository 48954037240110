import React from 'react';

const Testimonial: React.FC = () => {
  return (
    <div id="testimonial" className="testimonial">
      <div className="container">
        <div className="swiper mySwiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="content">
                <p>
                  "The Opera Coworking Lab isn't just an office space; it's a family. The energy here is electric, and the connections I've made have been invaluable for growing my startup. The workshops and mentorship programs offered by The Opera Group have been instrumental in taking my business to the next stage."
                </p>
                <div className="text">
                  <h5>Thrive in a supportive Ecosystem (Kwame A.):</h5>
                  <span>Founder</span>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="content">
                <p>
                  "The open layout and vibrant atmosphere at The Opera Lab spark creativity and collaboration. I love that I can bounce ideas off other entrepreneurs and get valuable feedback. The events and workshops have also helped me stay up-to-date on the latest trends and tools."
                </p>
                <div className="text">
                  <h5>Spark Innovation & Collaboration (Mumbi N)</h5>
                  <span>Creative Director</span>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="content">
                <p>
                  "The Opera Lab takes care of everything from high-speed internet to comfortable furniture, allowing me to focus on what matters most—growing my business. The flexible workspace options allow me to scale as my team expands. It is encouraging to know that the community around me supports my entrepreneurial spirit."
                </p>
                <div className="text">
                  <h5>Wesley</h5>
                  <span>Programming</span>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="content">
                <p>
                  "The Opera Lab takes care of everything from high-speed internet to comfortable furniture, allowing me to focus on what matters most—growing my business. The flexible workspace options allow me to scale as my team expands. It is encouraging to know that the community around me supports my entrepreneurial spirit."
                </p>
                <div className="text">
                  <h5>Focus on Your Business, Not the Logistics (David O.)</h5>
                  <span>CEO</span>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="content">
                <p>
                  "The Opera Group's commitment to sustainability resonates with my values. The Lab operates with eco-friendly practices, which is important to me. It's a great feeling to be working in a space that's good for the environment and my business."
                </p>
                <div className="text">
                  <h5>Sustainable Workspace for a Sustainable Future (Aisha S)</h5>
                  <span>CEO</span>
                </div>
              </div>
            </div>
          </div>
          {/* Navigation Buttons */}
          <div className="swiper-button-prev prev-slide"></div>
          <div className="swiper-button-next next-slide"></div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
