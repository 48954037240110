import React from 'react';

const Gallery: React.FC = () => {
  return (
    <div id="gallery" className="gallery">
      <div className="container">
        <div className="row">
          <div className="col-md">
            <h2>Our Gallery</h2>
          </div>

          <div className="col-md">
            <p>We offer a diverse range of services, culinary experiences, and wine tastings, all designed to foster creativity, collaboration, and professional growth.
            </p>
            <p>We sort the necessary so that you can focus on being the best at what you do!
            </p>
          </div>
        </div>

        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>

          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="https://images.pexels.com/photos/11048260/pexels-photo-11048260.jpeg"
                alt="Gallery image 1"
                className="d-block w-100"
              />
            </div>
            <div className="carousel-item">
              <img
                src="https://cdn.pixabay.com/photo/2023/10/10/09/45/digital-nomadism-8305984_1280.jpg"
                alt="Gallery image 2"
                className="d-block w-100"
              />
            </div>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
