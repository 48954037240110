import React from 'react';

const Contact: React.FC = () => {
  return (
    <div id="contact" className="contact">
      <div className="container">
        <div className="row gx-5">
          {/* Left Column: Google Maps */}
          <div className="col-md col-sm-12">
            <div className="content-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d249.30324330609105!2d36.784618209947084!3d-1.2611143411039436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWestcom!5e0!3m2!1sen!2ske!4v1726680122913!5m2!1sen!2ske"
                height="510"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                title="Google Map of Westcom"
              ></iframe>
            </div>
          </div>

          {/* Right Column: Contact Details */}
          <div className="col-md col-sm-12">
            <h2>Get In Touch</h2>
            <p>Having you join our community of dreamers and doers is also a #superpower</p>

            {/* Address */}
            <div className="row">
              <div className="col">
                <div className="icon">
                  <i className="la la-city"></i>
                </div>
                <div className="text">
                  <h5>Nairobi</h5>
                  <span>Westcom Point, Mahiga Mairu Avenue, Westlands</span>
                </div>
              </div>
            </div>

            {/* Email and Phone */}
            <div className="row">
              <div className="col">
                <div className="icon">
                  <i className="la la-envelope"></i>
                </div>
                <div className="text">
                  <h5>Email</h5>
                  <span>hello@theoperagroup.org</span>
                </div>
              </div>
              <div className="col">
                <div className="icon">
                  <i className="la la-phone"></i>
                </div>
                <div className="text">
                  <h5>Phone</h5>
                  <span>+254 745 903344</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
